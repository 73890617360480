// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gig-js": () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\src\\templates\\gig.js" /* webpackChunkName: "component---src-templates-gig-js" */),
  "component---src-templates-artist-js": () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\src\\templates\\artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-tag-js": () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\src\\templates\\tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-venue-js": () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\src\\templates\\venue.js" /* webpackChunkName: "component---src-templates-venue-js" */),
  "component---src-pages-404-js": () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("E:\\Documents\\React\\gatsby\\strapi\\gatsby-strapi-tutorial\\blog\\.cache\\data.json")

